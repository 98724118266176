import React from 'react'
import { BiMailSend } from 'react-icons/bi'
import axios from 'axios'

export const info = {
    email: '',
    name: '',
    contact: '',
    message: ''
}

export function reducer(state, action) {
    switch (action.type) {
        case 'field':
            return {
                ...state,
                [action.name]: action.data
            }
        case 'clear':
            return {
                ...state,
                email: '',
                name: '',
                address: '',
                contact: '',
                services: '',
                message: ''
            }
    }
}

const ContactSection = ({ scroll, reset, setactive }) => {
    const [state, dispatch] = React.useReducer(reducer, info)
    const [sending, setsending] = React.useState(false)
    const URL_ROOT = "https://syntaxworkgroup.net/_access"
    const scrollref = React.useRef()
    const values = state

    React.useEffect(() => {
        if (scroll) {
            scrollref.current.scrollIntoView({ behavior: 'smooth' })
            reset(false)
        }
    }, [scroll])

    function onIntersection(entries, opts) {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                setactive("contact")
                console.log("contact")
            }
        })
    }

    React.useEffect(() => {
        const observer = new IntersectionObserver(onIntersection, {
            root: null,
            threshold: 0.1
        })
        if (scrollref.current) observer.observe(scrollref.current)

        return () => {
            if (scrollref.current) observer.unobserve(scrollref.current)
        }
    }, [scrollref])

    const valueChange = (e) => {
        const { name, value } = e.target
        dispatch({ type: 'field', name: name, data: value.toUpperCase() })
    }

    const emailChange = (e) => {
        const { name, value } = e.target
        dispatch({ type: 'field', name: name, data: value.toLowerCase() })
    }

    const mail = () => {
        if (sending) return
        setsending(true)
        axios['get'](`${URL_ROOT}/emailer.php`, {
            params: {
                i: "Inquiry",
                e: values.email,
                n: values.name,
                c: values.contact,
                m: values.message
            }
        }).then((ret) => {
            if (!ret.data.RESULT) {
                alert("Message failed")
                return
            }
            dispatch({ type: 'clear' })
            setsending(false)
            alert("Message sent!")
        }).catch((err) => {
            console.log(err)
            setsending(false)
        })
    }

    return (
        <div ref={scrollref} className="w-full bg-[#0e7af7] text-[#ffffff] flex flex-col md:flex-row p-[40px]">
            <div className="w-full flex justify-center mb-[40px]">
                <div className="flex flex-col">
                    <div className="text-[25px] font-bold">Our Contact Information</div>
                    <div className="text-[18px] mt-[10px]">6403 Paddington Street</div>
                    <div className="text-[18px]">Middle River, MD 21220</div>
                    <div className="text-[18px] mt-[5px]">Phone: (410) 842-3706</div>
                    <div className="text-[18px] mt-[5px]">Email: dcmielke@gmail.com</div>
                    <div className="text-[25px] font-bold mt-[15px]">Business Hours:</div>
                    <div className="text-[18px] mt-[10px]">8:00 AM - 8:00 PM | Sunday to Saturday</div>
                </div>
            </div>
            <div className="w-full flex flex-col" autoComplete="off">
                <div className="text-[25px] font-bold">Have a question? Let us help!</div>
                <div className="w-full mt-[10px] bg-[#ffffff] px-[10px] py-[5px]">
                    <input type="text" name="name" value={values.name} onChange={valueChange} className="w-full outline-none text-[#000000]" placeholder="Name" autoComplete="new" required />
                </div>
                <div className="w-full mt-[10px] bg-[#ffffff] px-[10px] py-[5px]">
                    <input type="email" name="email" value={values.email} onChange={emailChange} className="w-full outline-none text-[#000000]" placeholder="Email Address" autoComplete="new" required />
                </div>
                <div className="w-full mt-[10px] bg-[#ffffff] px-[10px] py-[5px]">
                    <input type="text" name="contact" value={values.contact} onChange={valueChange} className="w-full outline-none text-[#000000]" placeholder="Phone" autoComplete="new" required />
                </div>
                <div className="w-full mt-[10px] bg-[#ffffff] px-[10px] py-[5px]">
                    <textarea name="message"  value={values.message} onChange={valueChange} cols="30" rows="5" placeholder="Message" className="w-full outline-none text-[#000000]" required></textarea>
                </div>
                <div className="flex w-full mt-[15px] text-[#ffffff]">
                    <button className="flex justify-center items-center ml-auto px-[20px] py-[10px] bg-[#0a6ad8] rounded-[10px]" onClick={() => mail()}>
                        Send&emsp;<BiMailSend />
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ContactSection