import React from 'react'

const ErrorPage = () => {

    return (
        <div className="w-screen h-screen flex items-center justify-center text-[25px] bg-[#aaa7a7]">
            Page not found!
        </div>
    )
}

export default ErrorPage