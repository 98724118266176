import React from 'react'

const MenuSection = ({ active, cover, intro, service, about, contact }) => {

    return (
        <>
            <div className="w-full bg-[#0e7af7] text-white p-[20px] hidden lg:flex items-center justify-center text-[18px] font-bold text-opacity-80">
                <div className={`cursor-pointer hover:text-[#2e3e83] transition duration-200 ${active === "cover" ? "text-[#ffffff]" : "text-[#bcc2f7]"}`} onClick={() => cover(true)}>HOME</div>
                <div>&emsp;|&emsp;</div>
                <div className={`cursor-pointer hover:text-[#2e3e83] transition duration-200 ${active === "intro" ? "text-[#ffffff]" : "text-[#bcc2f7]"}`} onClick={() => intro(true)}>WHY CHOOSE DCM?</div>
                <div>&emsp;|&emsp;</div>
                <div className={`cursor-pointer hover:text-[#2e3e83] transition duration-200 ${active === "service" ? "text-[#ffffff]" : "text-[#bcc2f7]"}`} onClick={() => service(true)}>OUR SERVICES</div>
                <div>&emsp;|&emsp;</div>
                <div className={`cursor-pointer hover:text-[#2e3e83] transition duration-200 ${active === "about" ? "text-[#ffffff]" : "text-[#bcc2f7]"}`} onClick={() => about(true)}>ABOUT US</div>
                <div>&emsp;|&emsp;</div>
                <div className={`cursor-pointer hover:text-[#2e3e83] transition duration-200 ${active === "contact" ? "text-[#ffffff]" : "text-[#bcc2f7]"}`} onClick={() => contact(true)}>CONTACT US</div>
            </div>
            <div className="w-full bg-[#0e7af7] text-white p-[20px] flex lg:hidden items-center justify-center text-[14px] font-bold text-opacity-80">
                <div className={`cursor-pointer hover:text-[#2e3e83] transition duration-200 ${active === "cover" ? "text-[#ffffff]" : "text-[#bcc2f7]"}`} onClick={() => cover(true)}>HOME</div>
                <div>&emsp;|&emsp;</div>
                <div className={`cursor-pointer hover:text-[#2e3e83] transition duration-200 ${active === "intro" ? "text-[#ffffff]" : "text-[#bcc2f7]"}`} onClick={() => intro(true)}>WHY DCM?</div>
                <div>&emsp;|&emsp;</div>
                <div className={`cursor-pointer hover:text-[#2e3e83] transition duration-200 ${active === "service" ? "text-[#ffffff]" : "text-[#bcc2f7]"}`} onClick={() => service(true)}>SERVICES</div>
                <div>&emsp;|&emsp;</div>
                <div className={`cursor-pointer hover:text-[#2e3e83] transition duration-200 ${active === "about" ? "text-[#ffffff]" : "text-[#bcc2f7]"}`} onClick={() => about(true)}>ABOUT</div>
                <div>&emsp;|&emsp;</div>
                <div className={`cursor-pointer hover:text-[#2e3e83] transition duration-200 ${active === "contact" ? "text-[#ffffff]" : "text-[#bcc2f7]"}`} onClick={() => contact(true)}>CONTACT</div>
            </div>
        </>

    )
}

export default MenuSection