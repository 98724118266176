import React from 'react'
import dave from '../../../assets/dave.png'

const AboutSection = ({ scroll, reset, setactive }) => {
    const scrollref = React.useRef()
    const [size, setsize] = React.useState(0)

    React.useEffect(() => {
        if (scroll) {
            scrollref.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: "nearest" })
            reset(false)
        }
    }, [scroll])

    function onIntersection(entries, opts) {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                setactive("about")
                console.log("about")
            }
        })
    }

    React.useEffect(() => {
        console.log(scrollref.current.clientWidth)
        function handleResize() {
            setsize(scrollref.current.clientWidth)
            console.log(scrollref.current.clientWidth)
        }
        window.addEventListener('resize', handleResize)
    })

    React.useEffect(() => {
        const observer = new IntersectionObserver(onIntersection, {
            root: null,
            threshold: size > 500 ? 0.7 : 0.2
        })
        if (scrollref.current) observer.observe(scrollref.current)

        return () => {
            if (scrollref.current) observer.unobserve(scrollref.current)
        }
    }, [scrollref, size])

    return (
        <div ref={scrollref} className="w-full mt-[40px] min-h-screen flex flex-col items-center bg-city text-[#ffffff]">
            <div className="flex min-h-screen bg-[#011b33] md:pr-[30px] bg-opacity-40">
                <div className="w-full relative hidden lg:flex items-center">
                    <img src={dave} alt="" className="flex border border-[5px] border-[#ffffff] bg-[#414246] hover:bg-[#2c364d] hover:bg-opacity-60 bg-opacity-50 shadow hover:shadow-2xl rounded-[30%] mr-auto ml-auto w-[90%] xl:w-[80%]" />
                </div>
                <div className="w-full h-fit flex flex-col py-[40px] px-[30px] items-center md:m-[40px] bg-[#022938] bg-opacity-80 rounded-[10px] glass-morph">
                    <div className="font-bold w-full flex justify-center text-[35px] lg:text-[40px]">
                        About Us
                    </div>
                    <div className="text-[30px] lg:text-[35px] mt-[30px] font-bold">Dave's Passion</div>
                    <div className="px-[10px] mt-[20px] text-[22px] lg:text-[24px] xl:text-[26px] text-center">
                        David has been providing professional home improvement services to the Baltimore area for over 30 years. He takes great pride in his work and delivers satisfaction with artistic passion and precision. The attention to details reflects the attitude that average is not good enough, that the quality of work must be superior in all ways. He strives for excellence in the home improvement sector.
                    </div>
                    <div className="text-[30px] lg:text-[35px] font-bold mt-[40px]">His Team's Mission</div>
                    <div className="px-[10px] mt-[20px] text-[22px] lg:text-[24px] xl:text-[26px] text-center">
                        It was this commitment to excellence that started DCM Custom Painting LLC. DCM Custom Painting employs the most talented professionals to ensure superb customer satisfaction with the completed final project. We listen to our customers and provide high quality services and advice to them, always with a personal touch. Understanding what the customer wants, and why, is very important towards complete satisfaction. We treat the customer’s home like our own, with respect, courtesy, and professionalism.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutSection