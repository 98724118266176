import React from 'react'
import service from '../../../assets/services.png'
import exterior from '../../../assets/exterior.jpg'
import interior from '../../../assets/interior.jpg'
import pressure from '../../../assets/pressure.jpg'
import wallpaper from '../../../assets/wallpaper.jpg'
import sheetrock from '../../../assets/sheetrock.jpg'
import cabinet from '../../../assets/cabinet.jpg'
import staining from '../../../assets/staining.jpg'
import family from '../../../assets/family.jpg'
import floor from '../../../assets/floor.jpg'
import commercial from '../../../assets/commercial.jpg'
import office from '../../../assets/office.jpg'

const ServiceSection = ({ scroll, reset, setactive }) => {
    const scrollref = React.useRef()

    React.useEffect(() => {
        if (scroll) {
            scrollref.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: "nearest" })
            reset(false)
        }
    }, [scroll])

    function onIntersection(entries, opts) {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                setactive("service")
                console.log("service")
            }
        })
    }

    React.useEffect(() => {
        const observer = new IntersectionObserver(onIntersection, {
            root: null,
            threshold: 0.2
        })
        if (scrollref.current) observer.observe(scrollref.current)

        return () => {
            if (scrollref.current) observer.unobserve(scrollref.current)
        }
    }, [scrollref])

    return (
        <div ref={scrollref} className="flex flex-none w-full h-fit bg-service">
            <div className="w-full py-[100px] bg-[#0e7af7] bg-opacity-80">
                <div className="flex flex-col lg:flex-row items-center lg:justify-center text-[25px] px-[100px]">
                    <div className="flex flex-col min-w-[430px] w-full max-w-[665px] items-center m-[20px] px-[20px] pb-[20px] bg-[#4397f7] drop-shadow-md border border-2 border-[#ffffff]">
                        <div className="text-[#ffffff] my-[20px]">Exterior Painting</div>
                        <div><img src={exterior} alt="" /></div>
                    </div>
                    <div className="flex flex-col min-w-[430px] w-full max-w-[665px] items-center m-[20px] px-[20px] pb-[20px] bg-[#4397f7] drop-shadow-md border border-2 border-[#ffffff]">
                        <div className="text-[#ffffff] my-[20px]">Interior Painting</div>
                        <div><img src={interior} alt="" /></div>
                    </div>
                </div>
                <div className="flex flex-wrap justify-center text-[16px] mb-[20px] px-[100px]">
                    <div className="flex flex-col max-w-[430px] min-w-[430px] items-center m-[20px] px-[20px] pb-[20px] bg-[#4397f7] drop-shadow-md">
                        <div className="text-[#ffffff] my-[20px]">Family Room Painting</div>
                        <div><img src={family} alt="" /></div>
                    </div>
                    <div className="flex flex-col max-w-[430px] min-w-[430px] items-center m-[20px] px-[20px] pb-[20px] bg-[#4397f7] drop-shadow-md">
                        <div className="text-[#ffffff] my-[20px]">Concrete Floor Painting</div>
                        <div><img src={floor} alt="" /></div>
                    </div>
                    <div className="flex flex-col max-w-[430px] min-w-[430px] items-center m-[20px] px-[20px] pb-[20px] bg-[#4397f7] drop-shadow-md">
                        <div className="text-[#ffffff] my-[20px]">Commercial Interiors</div>
                        <div><img src={commercial} alt="" /></div>
                    </div>
                    <div className="flex flex-col max-w-[430px] min-w-[430px] items-center m-[20px] px-[20px] pb-[20px] bg-[#4397f7] drop-shadow-md">
                        <div className="text-[#ffffff] my-[20px]">Office Space</div>
                        <div><img src={office} alt="" /></div>
                    </div>
                    <div className="flex flex-col max-w-[430px] min-w-[430px] items-center m-[20px] px-[20px] pb-[20px] bg-[#4397f7] drop-shadow-md">
                        <div className="text-[#ffffff] my-[20px]">Cabinet Painting</div>
                        <div><img src={cabinet} alt="" /></div>
                    </div>
                    <div className="flex flex-col max-w-[430px] min-w-[430px] items-center m-[20px] px-[20px] pb-[20px] bg-[#4397f7] drop-shadow-md">
                        <div className="text-[#ffffff] my-[20px]">Wallpaper Removal</div>
                        <div><img src={wallpaper} alt="" /></div>
                    </div>
                    <div className="flex flex-col max-w-[430px] min-w-[430px] items-center m-[20px] px-[20px] pb-[20px] bg-[#4397f7] drop-shadow-md">
                        <div className="text-[#ffffff] my-[20px]">Staining</div>
                        <div><img src={staining} alt="" /></div>
                    </div>
                    <div className="flex flex-col max-w-[430px] min-w-[430px] items-center m-[20px] px-[20px] pb-[20px] bg-[#4397f7] drop-shadow-md">
                        <div className="text-[#ffffff] my-[20px]">Pressure Washing</div>
                        <div><img src={pressure} alt="" /></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceSection