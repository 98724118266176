import React from 'react'
import AboutSection from "./component/about"
import CitiesSection from "./component/cities"
import ClientSection from "./component/clients"
import ContactSection from "./component/contact"
import CoverSection from "./component/cover"
import HeaderSection from "./component/header"
import IntroSection from "./component/intro"
import MenuSection from "./component/menu"
import ServiceSection from "./component/service"

const LandingPage = () => {
    const [cover, scrolltocover] = React.useState(false)
    const [intro, scrolltointro] = React.useState(false)
    const [service, scrolltoservice] = React.useState(false)
    const [about, scrolltoabout] = React.useState(false)
    const [contact, scrolltocontact] = React.useState(false)
    const [active, setactive] = React.useState("cover")

    return (
        <div className="bg-[#ffffff] text-[#000000]">
            <HeaderSection />
            <div className="sticky top-0 left-0 z-20">
                <MenuSection active={active} cover={scrolltocover} intro={scrolltointro} service={scrolltoservice} about={scrolltoabout} contact={scrolltocontact} />
            </div>
            <CoverSection scroll={cover} reset={scrolltocover} setactive={setactive} />
            <IntroSection scroll={intro} reset={scrolltointro} setactive={setactive} />
            <ServiceSection scroll={service} reset={scrolltoservice} setactive={setactive}  />
            <ClientSection />
            <CitiesSection />
            <AboutSection scroll={about} reset={scrolltoabout} setactive={setactive} />
            <ContactSection scroll={contact} reset={scrolltocontact} setactive={setactive}  />
        </div>
    )
}

export default LandingPage