import { HashRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import LandingPage from '../pages/public/landing'
import ErrorPage from '../pages/redirect/error'

const RouterRoot = () => {
    return (
        <Router>
            <Switch>
                <Route path="/home" component={LandingPage} />
                <Redirect from='/' to="/home" exact />
                <Route path='*' exact={true} component={ErrorPage} />
            </Switch>
        </Router>
    )
}

export default RouterRoot